<template>
  <div class="search">
    <div v-if="introspect.classes">
      <div v-if="manual_get_needed" class="alert alert-warning" role="alert">Data request triggered, please reload manually</div>
      <!-- Search Bar -->
      <b-row v-if="allow_searchbar">
        <b-col md="12" class="my-1">
          <SearchInput
            :search.sync="search"
            :disabled="disabled['search']"
            :with_properties="index_with_properties"
            :is_mysql_view_materialized="mysql_view_materialized"
            :size="small ? 'sm' : null"
            :class_name="class_name"
            @refresh="get(null, true)"
            @send_last_updated_date_time="lastUpdatedDatetimeForMysqlViewMaterialized"
          />
        </b-col>
      </b-row>
      <!-- <br> -->
      <!-- Search Date -->
      <b-row class="toggles">
        <b-col v-if="allow_json_filter" cols="12" sm="4" md="3" lg="2" xl="2" class="my-1">
          <b-form-group label="JSON" :label-cols="6" :label-cols-sm="6">
            <c-switch
              class="mx-2"
              v-model="enable_json_filter"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="allow_predefined_filter && predefined_filters.length" cols="12" sm="4" md="3" lg="2" xl="2" class="my-1">
          <b-form-group label="Predefined" :label-cols="6" :label-cols-sm="6">
            <c-switch
              class="mx-2"
              v-model="enable_predefined_filter"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="allow_properties_filter" cols="12" sm="4" md="3" lg="2" xl="2" class="my-1">
          <b-form-group label="Properties" :label-cols="6" :label-cols-sm="6">
            <c-switch
              class="mx-2"
              v-model="enable_properties_filter"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="allow_relationships_filter" cols="12" sm="4" md="3" lg="2" xl="2" class="my-1">
          <b-form-group label="Relations" :label-cols="6" :label-cols-sm="6">
            <c-switch
              class="mx-2"
              v-model="enable_relationships_filter"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="is_map_available" cols="12" sm="4" md="3" lg="2" xl="2" class="my-1">
          <b-form-group label="Show Map" :label-cols="6" :label-cols-sm="6">
            <c-switch
              class="mx-2"
              v-model="enable_map"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="allow_select_fields" cols="12" sm="6" md="6" lg="4" xl="4" class="my-1">
          <Multiselect
            v-model="select_propeties"
            :options="
              _.map(properties, property => {
                let option = {};
                option = Object.assign(option, property);
                if (property['virtual_property_parameters']) {
                  option.$isDisabled = true;
                }
                return option;
              })
            "
            :searchable="false"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Choose Fields"
            label="property_name"
            track-by="property_key"
            :preselect-first="false"
            deselect-label="Remove"
            select-label="Add"
            selected-label=""
            :show-labels="true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <!-- <span class="multiselect__single" v-if="values.length">{{values.length == properties.length ? 'All ' : ''}}{{ values.length }} fields</span> -->
              <span class="multiselect__single" v-if="values.length">{{ values.length }} of {{ properties.length }} fields</span>
            </template>
          </Multiselect>
          <!-- <b-form-group label="Select Fields" :label-cols="6" :label-cols-sm="6">
            <b-btn id="select_fields" class="mx-2" color="success" :size="small ? 'sm' : null">Select</b-btn>
            <b-popover :target="'select_fields'" triggers="click">
              <b-table
                selectable
                :select-mode="'multi'"
                :items="_.map(properties, property => {
                  return property
                })"
                :fields="[ 'property_name']"
                @row-selected="onFieldsSelected"
                responsive="sm"
              >
                <template v-slot:cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
              </b-table>
            </b-popover>
          </b-form-group> -->
        </b-col>
      </b-row>

      <!-- JSON Filters -->
      <hr v-if="enable_json_filter" />
      <b-row v-show="enable_json_filter" class="align-items-center">
        <b-col cols="12" class="my-1">
          <b>JSON</b>
        </b-col>
        <b-col cols="12" class="mb-3 mb-xl-0">
          <!-- <b-form-textarea v-model="json_filter" placeholder="..." rows="3" max-rows="6"></b-form-textarea>
          <div v-if="!is_json_filter_valid" class="alert alert-danger">JSON is Invalid</div>-->
          <VJsoneditor v-model="json_filter" :plus="false" height="300px" :options="{ mode: 'code' }" />
        </b-col>
      </b-row>

      <!-- Predefined Filters -->
      <hr v-if="enable_predefined_filter && predefined_filters.length" />
      <b-row v-show="enable_predefined_filter && predefined_filters.length" class="align-items-center g-2">
        <b-col cols="12" class="my-1">
          <b>Predefined Filters</b>
        </b-col>
        <b-col v-for="(predefined_filter, index) in predefined_filters" :key="index" cols="12" sm="6" md="4" lg="3" xl="2" class="mb-3">
          <b-button
          style="white-space: break-spaces;"
            :size="small ? 'sm' : null"
            block
            :pressed="enabled_predefined_filter_names.includes(predefined_filter['filter_name'])"
            @click="enabled_predefined_filter_names = _.xor(enabled_predefined_filter_names, [predefined_filter['filter_name']])"
            :variant="'outline-' + _.get(predefined_filter, ['frontend', 'variant'], 'dark')"
            aria-pressed="true"
            >{{ predefined_filter['filter_name'] }}</b-button>
        </b-col>
      </b-row>

      <!-- Properties Filter -->
      <hr v-if="enable_properties_filter" />
      <b-row v-show="enable_properties_filter">
        <b-col cols="12" class="my-1">
          <b>Properties</b>
        </b-col>
        <b-col cols="12" class="my-1">
          <PropertiesFilter v-show="enable_properties_filter" :properties="properties" :params.sync="simple_properties_filter" />
        </b-col>
      </b-row>

      <!-- Relationship Filter -->
      <hr v-if="enable_relationships_filter" />
      <b-row v-if="has_relationship_with_classes && has_relationship_with_classes.length > 0" v-show="enable_relationships_filter">
        <b-col cols="12" class="my-1">
          <b>Relationship To</b>
        </b-col>
        <b-col v-for="(item, index) of has_relationship_with_classes" :key="index" md="6" class="my-1">
          <b-form-group :label-cols="6" :label-cols-sm="6" :label="item.dropdown_label" :label-size="small ? 'sm' : null" class="mb-0">
            <FilterDropdown
              :ref="item.to_class_name + 'Dropdown'"
              :selected_option.sync="simple_relationships_filter[item.filter_key]"
              :target_class_name="item.target_class_name"
              :class_name="item.from_class_name"
              :filter_class_name="item.to_class_name"
              :relationship="item.relationship"
              :size="small ? 'sm' : null"
              :enabled="enable_relationships_filter"
            ></FilterDropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <hr v-if="enable_relationships_filter" />
      <b-row v-if="have_classes_relationship_with && have_classes_relationship_with.length > 0" v-show="enable_relationships_filter">
        <b-col cols="12" class="my-1">
          <b>Relationship From</b>
        </b-col>

        <b-col v-for="(item, index) of have_classes_relationship_with" :key="index" md="6" class="my-1">
          <b-form-group :label-cols="6" :label-cols-sm="6" :label="item.dropdown_label" :label-size="small ? 'sm' : null" class="mb-0">
            <FilterDropdown
              :ref="item.from_class_name + 'Dropdown'"
              :selected_option.sync="simple_relationships_filter[item.filter_key]"
              :target_class_name="item.target_class_name"
              :class_name="item.from_class_name"
              :filter_class_name="item.to_class_name"
              :relationship="item.relationship"
              :size="small ? 'sm' : null"
              :enabled="enable_relationships_filter"
            ></FilterDropdown>
          </b-form-group>
        </b-col>
      </b-row>

      <hr v-if="is_map_available && enable_map" />
      <b-row v-if="is_map_available && enable_map">
        <b-col sm="12">
          <PolygonMapView :extParam.sync="pagination.data" :class_name="class_name" :show_ids="ids_to_show_on_map" />
        </b-col>
      </b-row>
      <br />
      <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
      <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
      <!-- Search Table -->

      <b-row>
        <b-col sm="12">
          <div v-if="allow_top_pagination" style="display:block; width:100%">
            <Pagination
              :from="pagination.from"
              :to="pagination.to"
              :total="pagination.total"
              :per_page="pagination.per_page"
              :page.sync="pagination.page"
              :response_datetime="response_datetime"
              :timetaken="timetaken"
              :latest_d_materialize="latest_d_materialize"
              :is_mysql_view_materialized="mysql_view_materialized"
              :small="small"
              :disabled="loading"
            />
            <!-- <span class="float-left">{{ pagination.from }} - {{ pagination.to }} / {{ pagination.total }}</span>
              <span v-if="timetaken" class="float-left">&nbsp; ({{ timetaken / 1000 }}s)</span>

              <span class="float-right">
                <b-pagination
                  align="right"
                  :size="small ? 'sm' : ''"
                  :limit="8"
                  hide-ellipsis
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  v-model="pagination.page"
                  prev-text="Prev"
                  next-text="Next"
                  first-text="First"
                  last-text="Last"
                />
              </span> -->
          </div>
          <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />

          <slot name="search_top_slot" v-bind="{ pagination }"></slot>

          <template v-if="(typeof pagination.from !== 'undefined' && !pagination.total) || (pagination.data && pagination.data.length > 0)">
            <b-table
              v-if="enable_table"
              class="index_table"
              :class="{ clickable: row_clickable }"
              :small="small"
              hover
              striped
              responsive
              :bordered="$mq !== 'xs'"
              :stacked="$mq == 'xs'"
              head-variant="dark"
              :items="table_items"
              :fields="updatedFields"
              :no-local-sorting="true"
              :sort-by.sync="sort_by"
              :sort-desc.sync="sort_desc"
              @row-clicked="rowClicked"
              @row-hovered="rowHovered"
              @row-unhovered="rowUnhovered"
            >
              <template
                v-for="field in updatedFields.filter(field => (field['_slot_name'] ? true : false))"
                :slot="'cell(' + field['key'] + ')'"
                slot-scope="row"
              >
                <slot :name="field['_slot_name']" v-bind="row"></slot>
              </template>

              <template v-for="field in updatedFields.filter(field => (field.property ? true : false))" :slot="'cell(' + field.key + ')'" slot-scope="row">
                <div :key="field.key">
                  <template v-if="_.get(field.property, ['type']) == 'base64'">
                    <div class="hidden_copyable_text">{{ row.value }}</div>
                    <img :src="row.value" alt class="preview-image" />
                  </template>
                  <template v-if="_.get(field.property, ['type']) == 'file'">
                    <!-- {{row.item}} -->
                    <!-- {{$d.getFileTempurlParam(field.property, row.item)}} -->
                    <div class="hidden_copyable_text">{{ $d.getFileTempurlParam(field.property, row.item) }}</div>
                    <img v-if="$d.isImageUrl($d.getFileTempurlParam(field.property, row.item))" :src="$d.getFileTempurlParam(field.property, row.item)" alt class="preview-image" />
                    <!-- TODO: disable if file is not an image -->
                  </template>
                  <template v-else-if="_.get(field.property, ['frontend', 'color_picker'])">
                    <div class="hidden_copyable_text">{{ row.value }}</div>
                    <div :style="`width:21px;height:21px;border-radius:50%;background:${row.value}`"></div>
                  </template>
                  <template v-else>{{ row.value }}</template>
                </div>
              </template>

              <template v-slot:cell(Details)="row">
                <b-button-group>
                  <b-button @click.stop="rowClicked(row.item)" size="sm" variant="default">
                    <i class="fa fa-list"></i>
                  </b-button>
                </b-button-group>
              </template>

              <template v-slot:cell(show_in_map_select)="row" v-if="is_map_available" style="white-space: nowrap;">
                <b-button
                  variant="outline-secondary"
                  :pressed="ids_to_show_on_map.includes(row.item['id'])"
                  :key="row.item['id']"
                  size="sm"
                  @click="
                    () => {
                      let checked = ids_to_show_on_map.includes(row.item['id']);
                      checked = !checked; //toggle state
                      if (checked == true) {
                        enable_map = true;
                        ids_to_show_on_map.push(row.item['id']);
                      } else {
                        ids_to_show_on_map = ids_to_show_on_map.filter(id => id !== row.item['id']);
                      }
                    }
                  "
                  >
                    <i class="fa fa-map-marker "></i>
                </b-button>
              </template>
            </b-table>
          </template>
          <div v-else-if="pagination.data && pagination.data.length == 0">
            <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
            <div class="alert alert-warning" role="alert">No Results</div>
          </div>
          <div v-else>
            <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
            <div v-if="loading" class="alert alert-dark" role="alert">Loading...</div>
            <div v-else class="alert alert-dark" role="alert">Not Loaded</div>
          </div>

          <slot name="search_bottom_slot" v-bind="{ pagination }"></slot>

          <b-row style="flex-direction: row-reverse;">
            <b-col v-if="allow_bottom_pagination" style="margin-top: 5px; display:block; width:100%">
              <Pagination
                :from="pagination.from"
                :to="pagination.to"
                :total="pagination.total"
                :per_page="pagination.per_page"
                :page.sync="pagination.page"
                :response_datetime="response_datetime"
                :timetaken="timetaken"
                :latest_d_materialize="latest_d_materialize"
                :is_mysql_view_materialized="mysql_view_materialized"
                :small="small"
                :disabled="loading"
              />
              <!-- <span class="float-left">{{ pagination.from }} - {{ pagination.to }} / {{ pagination.total }}</span>
                <span v-if="timetaken" class="float-left">&nbsp; ({{ timetaken / 1000 }}s)</span>

                <span class="float-right">
                  <b-pagination
                    align="right"
                    :size="small ? 'sm' : ''"
                    :limit="8"
                    hide-ellipsis
                    :total-rows="pagination.total"
                    :per-page="pagination.per_page"
                    v-model="pagination.page"
                    prev-text="Prev"
                    next-text="Next"
                    first-text="First"
                    last-text="Last"
                  />
                </span> -->
            </b-col>
          </b-row>

          <b-row style="flex-direction: row-reverse;">
            <!-- ref: https://bootstrap-vue.js.org/docs/components/table/#complete-example -->
            <b-col v-if="allow_per_page" cols="12" sm="6" class="my-1">
              <div style="width: 250px; max-width: 100%" class="float-right">
                <b-form-group :label-size="small ? 'sm' : ''" :label-cols="6" label="Per page" class="mb-1">
                  <b-form-select
                    :size="small ? 'sm' : ''"
                    :options="per_page_options"
                    v-model="selected_per_page"
                    @input="
                      value => {
                        pagination.page = 1;
                        pagination.per_page = value;
                      }
                    "
                  />
                </b-form-group>
              </div>
            </b-col>
            <slot name="search_bottom_row_slot" v-bind="{ pagination }"></slot>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button-group class="mx-1">
            <b-button v-if="enable_excel_download" variant="outline-primary" @click="downloadExcel()">
              <span v-if="!downloading">Export ALL to Excel</span>
              <span v-else>
                Exporting ALL &nbsp;
                <q-spinner color="primary" size="17px" :thickness="2" />
              </span>
            </b-button>
          </b-button-group>
          <slot name="export_button_row_slot" v-bind="{ pagination }"></slot>
        </b-col>
      </b-row>

      <slot name="bottom_button_row_slot" v-bind="{ pagination }"></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Switch as cSwitch } from '@coreui/vue';
import SearchInput from './components/modal/SearchInput';
import FilterDropdown from './components/modal/FilterDropdown';
import DateDropdown from './DateDropdown';
import PolygonMapView from './PolygonMapView';
import Pagination from './Pagination';
import PropertiesFilter from './components/modal/PropertiesFilter';
import { QLinearProgress, QSpinner } from 'quasar';
import VJsoneditor from 'v-jsoneditor';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'Search',
  components: {
    PolygonMapView,
    Pagination,
    FilterDropdown,
    mapGetters,
    cSwitch,
    SearchInput,
    DateDropdown,
    PropertiesFilter,
    QLinearProgress,
    QSpinner,
    VJsoneditor,
    Multiselect,
  },
  props: {
    row_clickable: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    filter_defaults: {
      type: Object,
      default: () => {
        return {};
      },
    },
    enable_map_default: {
      type: Boolean,
      default: true,
    },
    enable_table: {
      type: Boolean,
      default: true,
    },
    fields: {
      type: Array,
    },
    stateName: {
      type: String,
      default: null,
    },
    max_cache_age_sec: {
      type: Number,
      default: 12 * 60 * 60, //default max 12 hours but if manual_get, will still show even if expired
    },
    default_page: {
      type: Number,
      default: 1,
    },
    default_per_page: {
      type: [Number, String],
      default: 25,
    },
    _default_properties_sort: {
      type: Array,
    },
    _index_params: {
      type: Object,
    },
    debounce_interval: {
      type: Number,
      // default: 500,
      // default: 750,
      // default: 1000,
      default: 1500,
      // default: 2000,
      // default: 3000,
    },
    disabled: {
      type: Object,
      default: () => {
        return {
          search: false,
          has_billing: false,
          is_disabled: false,
        };
      },
    },
    class_name: {
      type: String,
      default: null,
    },
    enable_excel_download: {
      type: Boolean,
      default: false,
    },
    allow_top_pagination: {
      type: Boolean,
      default: true,
    },
    allow_bottom_pagination: {
      type: Boolean,
      default: true,
    },
    allow_per_page: {
      type: Boolean,
      default: true,
    },
    allow_searchbar: {
      type: Boolean,
      default: true,
    },
    allow_predefined_filter: {
      type: Boolean,
      default: true,
    },
    allow_json_filter: {
      type: Boolean,
      default: true,
    },
    allow_properties_filter: {
      type: Boolean,
      default: true,
    },
    allow_relationships_filter: {
      type: Boolean,
      default: true,
    },
    allow_map: {
      type: Boolean,
      default: true,
    },
    allow_select_fields: {
      type: Boolean,
      default: true,
    },
    _predefined_filters: {
      type: Array,
    },
    default_predefined_filter_names: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _property_cell_variants: {
      type: Array,
    },
    manual_get: {
      type: Boolean,
      default: false,
    },
    select_propeties_even_with_property_tags: {
      type: Boolean,
      default: false,
    },
    with_childs_mode: {
      //IMPORTANT TODO: this is a temporary solution, use isWithRelationshipsSatisfied instead
      type: Boolean,
      default: false,
    },
    with_relationships: {
      type: Array,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      has_relationship_param_name: null,
      has_relationship_with_classes: null,
      have_classes_relationship_with: null,
      enable_predefined_filter: false,
      enable_json_filter: false,
      enable_properties_filter: false,
      enable_relationships_filter: false,
      enable_map: false,
      loading: false,
      response_datetime: null,
      prev_source: null,
      prev_source_eager_load: null,
      pagination_: null,
      downloading: false,
      timetaken: null,
      latest_d_materialize: null,
      has_inited_get: false,
      enabled_predefined_filter_names: [],
      simple_properties_filter: {},
      simple_relationships_filter: {},
      json_filter: {},
      // json_filter: null,
      sort_by: null,
      sort_desc: null,
      search: null,
      loaded_from_server: false,
      extra_per_page_options: [],
      selected_per_page: null,
      ids_to_show_on_map: [],
      eager_loaded_by_id: {},
      select_propeties: [],
      manual_get_needed: false,
      ideal_params: {
        class_name: null,
      },
    };
  },
  computed: {
    updatedFields() {
      var fields = [];

      if (!this.fields && this.class_name) {
        fields = fields.concat(this.$d.getClassBTableFields(this.class_name));
      }

      if (this.fields) {
        fields = fields.concat(this.fields);
      }

      fields = fields.filter(field => {
        let property = field.property;
        if (property) {
          return this.select_property_keys.includes(property['property_key']);
        }
        return true;
      });

      //TODO: repeated across Search.vue, PendingTable.vue & SelectingSearch.vue
      fields = _.map(fields, field => {
        if (field.property) {
          let property = field.property;
          if (property['type'] == 'number') {
            if (typeof field.thClass === 'string') {
              field.thClass = [field.thClass]; //convert to Array
            }
            if (!Array.isArray(field.thClass)) {
              field.thClass = []; //make sure it is array
            }
            field.thClass.push('text-right');

            if (typeof field.tdClass === 'string') {
              field.tdClass = [field.tdClass]; //convert to Array
            }
            if (!Array.isArray(field.tdClass)) {
              field.tdClass = []; //make sure it is array
            }

            field.tdClass.push('text-right');
          }
        }
        return field;
      });

      if (this.is_map_available && !this.small) {
        fields = [{ key: 'show_in_map_select', label: 'Show In Map', tdClass: ['no_break'] }].concat(fields); //TODO: move this to DescriptorCard
      }


      return fields;
    },
    introspect() {
      return this.$d.introspect;
    },
    class_() {
      return this.$d.getClass(this.class_name);
    },
    from_relationships() {
      return this.$d.getRelationships(this.class_['class_name'], 'from');
    },
    per_page_options() {
      let default_per_page_options = [5, 25, 50, 150, 500];

      let per_page_options = [];

      per_page_options = per_page_options.concat(default_per_page_options);

      if (this.extra_per_page_options) {
        per_page_options = per_page_options.concat(this.extra_per_page_options);
      }

      if (!per_page_options.includes(this.selected_per_page)) {
        per_page_options = per_page_options.concat([this.selected_per_page]);
      }

      per_page_options = _.sortBy(per_page_options);
      per_page_options = _.sortedUniq(per_page_options);
      // per_page_options = per_page_options.concat('all'); //disable get all (can cause browser to hang)
      return per_page_options;
    },
    properties() {
      console.log(this.class_name);
      var class_ = this.class_;
      console.log('class', class_);
      if (class_) {
        return class_['properties'];
      }
      return [];
    },
    mysql_view_materialized(){
      let mysql_view_materialized = false;

      var class_ = this.class_;
      console.log('class', class_);
      if (class_) {
        console.log('mysql_view_materialized', class_['mysql_view_materialized']);
        mysql_view_materialized = class_['mysql_view_materialized'];
      }

      return mysql_view_materialized;
    },
    predefined_filters() {
      return (this.class_['predefined_filters'] || []).concat(this._predefined_filters || []);
    },
    predefined_filters_index_params() {
      let predefined_filters_index_params = {};
      this.enabled_predefined_filter_names.forEach(enabled_predefined_filter => {
        let predefined_filter = _.find(this.predefined_filters, function(o) {
          return o['filter_name'] == enabled_predefined_filter;
        });

        if (predefined_filter) {
          this.$d.mergeFilterParams(predefined_filters_index_params, predefined_filter['filter_params']);
        } else {
          console.error('The enabled_predefined_filter: ' + enabled_predefined_filter + ' is not found');
        }
      });
      
      return predefined_filters_index_params;
    },
    simple_properties_sort() {
      let simple_properties_sort = [];
      let table_key = this.sort_by;
      let direction = this.sort_desc ? 'desc' : 'asc';
      if (table_key) {
        let field = _.find(this.updatedFields, { key: table_key });

        if (field && field.property) {
          //table key exists in field
          simple_properties_sort.push({
            property_key: field.property['property_key'],
            direction: direction,
          });
        } else {
          //table key does not exist in field, default to using table_key as property_key
          simple_properties_sort.push({
            property_key: table_key,
            direction: direction,
          });
        }
      }
      simple_properties_sort = simple_properties_sort.filter(property_sort => {
        if (property_sort['property_key'] == 'id') {
          //index_with_property_keys will not include 'id' so we have to take it in here
          return true;
        }
        return this.index_with_property_keys.includes(property_sort['property_key']);
      });
      return simple_properties_sort;
    },
    default_select_propeties() {
      let default_select_propeties = [];
      this.properties.forEach(property => {
        let force_with_virtual_properties =
          this._index_params &&
          this._index_params['with_properties'] &&
          (this._index_params['with_properties'].includes(property['property_key']) ||
            _.find(this._index_params['with_properties'], { property_key: property['property_key'] }));

        if (
          force_with_virtual_properties ||
          ((!property['property_tags'] || this.select_propeties_even_with_property_tags) && !property['virtual_property_parameters'])
        ) {
          default_select_propeties.push(property);
        }
      });

      return default_select_propeties;
    },
    select_property_keys() {
      return this.select_propeties.map(property => property['property_key']);
    },
    index_with_properties() {
      //TODO: include non virtual anyways
      let index_with_properties = [];

      if (this._index_params && this._index_params['with_properties']) {
        //merge with_properties
        index_with_properties = index_with_properties.concat(this._index_params['with_properties']);
      }

      this.properties.forEach(property => {
        let include = false;

        if (property['property_key'] !== 'id') {
          if (this.$d.propertyIsVirtual(property)) {
            if (this.select_property_keys.includes(property['property_key'])) {
              include = true;
            }
          } else {
            include = true;
          }
        }

        if (include) {
          if (!index_with_properties.includes(property['property_key']) && !_.find(index_with_properties, { property_key: property['property_key'] })) {
            index_with_properties.push(property);
          }
        }
      });

      return index_with_properties;
    },
    index_with_property_keys() {
      return this.index_with_properties.map(property => property['property_key']);
    },
    /* is_json_filter_valid() {
      //ref: https://codeblogmoney.com/validate-json-string-using-javascript/
      try {
        JSON.parse(this.json_filter);
      } catch (e) {
        return false;
      }
      return true;
    }, */
    map_property_path() {
      if (this.allow_map) {
        var class_ = this.class_;
        if (
          class_ &&
          class_['frontend'] &&
          class_['frontend']['index'] &&
          class_['frontend']['index']['map'] &&
          class_['frontend']['index']['map']['map_property_path']
        ) {
          return class_['frontend']['index']['map']['map_property_path'];
        }
      }
      return null;
    },
    eager_load_index_params() {
      let eager_load_index_params = {};
      if (this.enable_map) {
        console.log('map_property_path', this.map_property_path);
        let map_with_relationships = [];
        let last_with_relationships = null;
        if(this.map_property_path) {
          this.map_property_path.forEach(property_path_item => {
            let next_with_relationships = [];

            if (property_path_item['from_class_name'] && property_path_item['relationship_name']) {
              let curr_with_relationship = {
                from_class_name: property_path_item['from_class_name'],
                relationship_name: property_path_item['relationship_name'],
                with_relationships: next_with_relationships,
              };
              if (last_with_relationships) {
                last_with_relationships.push(curr_with_relationship);
              } else {
                map_with_relationships.push(curr_with_relationship);
              }
            }

            last_with_relationships = next_with_relationships;
          });
        }

        let ids_to_eager_load_map_relationships = [];
        this.ids_to_show_on_map.forEach(id_for_map => {
          console.log(this.pagination.data);
          let params = _.find(this.pagination.data, { id: id_for_map });
          console.log(params);

          if (params) {
            if (!this.$d.isWithRelationshipsSatisfied(params, map_with_relationships)) {
              console.log('need to eager load map relationships for', id_for_map);
              ids_to_eager_load_map_relationships.push(id_for_map);
            } else {
              console.log('map relationships already satisfied for', id_for_map);
            }
          }
        });

        if (ids_to_eager_load_map_relationships.length >= 1) {
          eager_load_index_params['with_relationships'] = map_with_relationships; //TODO: need to use merge instead
          eager_load_index_params['filters'] = [
            {
              filter_type: 'property',
              property_key: 'id',
              equal: ids_to_eager_load_map_relationships,
            },
          ];
        }
      }
      return _.isEmpty(eager_load_index_params) ? null : eager_load_index_params;
    },
    combined_index_params() {
      let index_params = {
        index_withs: true,
        with_properties: this.index_with_property_keys,
      };

      if (this.search) {
        let search = this.search.trim();

        if (search == '') {
          search = null;
        }
        if (search) {
          index_params['search'] = search;
        }
      }

      // if (this.allow_predefined_filter) { //commented to allow override from code, even though user control is disabled
      if (this.enable_predefined_filter) {
        index_params = _.merge(index_params, this.predefined_filters_index_params);
      }
      // }

      //TODO: depreciate direct relationships filter
      // if (this.allow_relationships_filter) { //commented to allow override from code, even though user control is disabled
      if (this.enable_relationships_filter) {
      index_params = _.merge(index_params, this.simple_relationships_filter);
      }
      // }

      let local_filter_params = {};
      // if (this.allow_properties_filter) { //commented to allow override from code, even though user control is disabled
      if (this.enable_properties_filter) {
      local_filter_params['properties_filter'] = this.simple_properties_filter;
      }
      // }

      local_filter_params['properties_sort'] = this.simple_properties_sort;

      this.$d.mergeFilterParams(index_params, local_filter_params);

      this.$d.mergeFilterParams(index_params, this._index_params); //will not allow any direct relationship filter

      if (!_.isNil(this.with_relationships)) {
        //sanitize
        if(!index_params['with_relationships']){
          index_params['with_relationships'] = [];
        }

        index_params['with_relationships'] = index_params['with_relationships'].concat(this.with_relationships);
      }

      this.$d.mergeNonFilterParams(index_params, this._index_params); //for things like with_childs

      index_params.per_page = _.get(this.pagination, ['per_page'], this.default_per_page);
      index_params.page = _.get(this.pagination, ['page'], 1);

      // if (this.allow_json_filter) { //commented to allow override from code, even though user control is disabled
      if(this.enable_json_filter){
        if (this.json_filter) {
          index_params = _.merge(index_params, this.json_filter);
        }
      }
      // }

      return index_params;
    },
    cache_key() {
      let cache_key = '';

      if (this.stateName) {
        cache_key += this.stateName;
      }

      cache_key += '_' + this.class_name;

      cache_key += '_' + this.$d.objHash(this.combined_index_params);
      return cache_key;
    },
    default_properties_sort() {
      if (this._default_properties_sort) {
        return this._default_properties_sort;
      }

      var class_ = this.class_;
      if (class_) {
        return _.get(class_, ['frontend', 'default_properties_sort'], []);
      }
      return [];
    },
    getPlaceholder() {
      var placeholder = '';
      var class_ = this.class_;

      // console.log('getPlaceholder class',this.class_name, class_);

      if (class_) {
        class_['properties'].forEach(property => {
          if (property['searchable']) {
            placeholder += property['property_name'] + '    ';
            // console.log('searchable: ', property['property_name']);
          }
        });
      }
      return placeholder;
    },
    is_map_available() {
      if (this.allow_map) {
        var class_ = this.class_;
        if (class_ && class_['frontend'] && class_['frontend']['index'] && class_['frontend']['index']['map']) {
          return true;
        }
      }
      return false;
    },
    pagination: {
      // getter
      get: function() {
        return this.pagination_;
      },
      // setter
      set: function(newValue) {
        this.pagination_ = newValue;
        this.$emit('update:pagination', newValue); //IMPORTANT TODO: cached data will also be pushed upstream. maybe separate cached and fresh?
      },
    },
    table_items() {
      //TODO: same as in Search.vue. PedningTable.vue & RecursiveView.vue
      return _.map(this.pagination.data, datum => {
        this.updatedFields.forEach(field => {
          let property_key = _.get(field.property, ['property_key']);
          if (field.cellVariantFunc) {
            if (!datum['_cellVariants']) {
              datum['_cellVariants'] = {};
            }
            let field_key = field['key'];
            datum['_cellVariants'][field_key] = field.cellVariantFunc(datum);
          }
        });

        if (this._property_cell_variants) {
          if (!datum['_cellVariants']) {
            datum['_cellVariants'] = {};
          }

          this._property_cell_variants.forEach(property_cell_variant => {
            let field = _.find(this.updatedFields, field => _.get(field.property, ['property_key']) == property_cell_variant['property_key']);

            if (field) {
              let field_key = field['key'];
              // console.log('property_cell_variant', field_key);
              datum['_cellVariants'][field_key] = property_cell_variant.cellVariantFunc(datum);
            } else {
              // console.warn('Cannot find field for cell variant:', property_cell_variant);
            }
          });
        }

        // datum['_rowVariant'] = 'danger';
        return datum;
      });
    },
  },
  watch: {
    class_name(to, from) {
      console.log('class_name changed');
      this.init();
    },
    combined_index_params: {
      deep: true,
      handler: function(to, from) {
        if (!_.isEqual(from, to)) {
          console.log('combined_index_params changed', from, to);
          // this.pagination['page'] = this.default_page;
          this.get();
        }
      },
    },
    eager_load_index_params: {
      deep: true,
      handler: function(to, from) {
        if (!_.isEqual(from, to)) {
          console.log('eager_load_index_params changed', from, to);
          if (this.eager_load_index_params) {
            this.eagerLoadDebounced(this.eager_load_index_params);
          }
        }
      },
    },
  },
  created() {
    this._ = _;
    this.get_debounced = _.debounce(this.get_undebounced, this.debounce_interval);
    this.eagerLoadDebounced = _.debounce(this.eagerLoad, 1500);
    //TODO: a big flaw when first load the page, introspect is not available, but a request has been made, so a watcher is added

    this.init();

    if (this.default_properties_sort && this.default_properties_sort.length > 0) {
      //currently only supports 1 sort
      let default_sort_property_key = this.default_properties_sort[0]['property_key'];
      let default_sort_direction = this.default_properties_sort[0]['direction'];
      let field = _.find(this.updatedFields, { property: { property_key: default_sort_property_key } });

      if (field) {
        this.sort_by = field['key'];
      } else {
        this.sort_by = default_sort_property_key;
      }

      this.sort_desc = default_sort_direction == 'desc' ? true : false;

      // console.log('default_properties_sort', this.default_properties_sort, this.sort_by, this.sort_desc)
    }
  },
  mounted() {},
  methods: {
    init() {
      this.enable_relationships_filter = _.get(this.filter_defaults, ['relationship'], false);
      this.enable_map = this.enable_map_default;

      this.enable_predefined_filter = _.get(this.filter_defaults, ['predefined'], true);
      this.enable_properties_filter = _.get(this.filter_defaults, ['properties'], false);

      this.enabled_predefined_filter_names = this.default_predefined_filter_names;

      this.simple_properties_filter = {};
      this.simple_relationships_filter = {};

      this.select_propeties = this.default_select_propeties;

      this.sort_by = null;
      this.sort_desc = null;

      this.search = '';

      this.setupHasRelationshipWithClasses();
      this.setupHaveClassesRelationshipWith();

      this.pagination = {
        per_page: null,
        page: null,

        //defined for reactivity sake
        data: null,
        from: null,
        to: null,
        total: null,
      };

      this.extra_per_page_options.push(this.default_per_page); //makes sure the default can be selected again
      this.selected_per_page = this.default_per_page;
      this.pagination['per_page'] = this.default_per_page;
      this.pagination['page'] = this.default_page;

      if (!this.has_inited_get) {
        this.get();
      }
    },
    rowClicked(item) {
      if (this.row_clickable) {
        this.$emit('rowClicked', item);
      }
      /* if (this.rowClicked) {
        this.rowClicked(item);
      } */
    },
    rowHovered(item) {
      this.$emit('rowHovered', item);
    },
    rowUnhovered(item) {
      this.$emit('rowUnhovered', item);
    },
    onFieldsSelected(items) {
      console.log('onFieldsSelected', items);
    },
    mergeWithEagerLoaded(data) {
      console.log('start mergeWithEagerLoaded', data);
      for (let id in this.eager_loaded_by_id) {
        let params = this.eager_loaded_by_id[id];
        console.log(params);
        let index = _.findIndex(data, { id: params['id'] });
        console.log(index);
        if (index >= 0) {
          data[index] = _.assign(data[index], params);
          console.log(_.cloneDeep(data[index]));
        }
      }
      return data;
    },
    getFromCache() {
      if (this.cache_key) {
        this.loaded_from_server = false;

        this.$d.getCache(this.cache_key, (pagination, cache_age) => {
          if (pagination) {
            if (cache_age >= this.max_cache_age_sec * 1000 && !this.manual_get) {
              console.log('cache too old, ignored and remove data from pagination', this.cache_key, pagination, cache_age);
              if (!this.loaded_from_server) {
                //do not clear data for now, causes inconvenience in UI
                // this.pagination.data = null; //so that it shows not loaded
              }
            } else {
              console.log('retrieved from cache', this.cache_key, pagination, cache_age);
              if (!this.loaded_from_server) {
                pagination.data = this.mergeWithEagerLoaded(pagination.data);
                console.log('mergeWithEagerLoaded', _.cloneDeep(pagination.data));
                this.pagination = _.assign(this.pagination, pagination);
                this.response_datetime = moment().subtract(cache_age / 1000, 'seconds');
              }
            }
          } else {
            console.log('no cache, remove data from pagination', this.cache_key);
            if (!this.loaded_from_server) {
              //do not clear data for now, causes inconvenience in UI
              if (this.manual_get) {
                this.pagination.data = null; //so that it shows not loaded
                this.response_datetime = null;
              }
            }
          }
        });
      }
    },
    get(extra_index_params, is_manual_get = false) {
      this.getFromCache(); //attempt to get from cache first while loading

      this.has_inited_get = true;
      if (!this.manual_get || is_manual_get) {
        console.log('attempt to get!');
        this.get_debounced(extra_index_params);
      } else {
        this.manual_get_needed = true;
      }
    },
    eagerLoad(index_params) {
      this.$NProgress.start();

      this.error_message = null;
      this.success_message = null;

      this.loading = true;

      let temp_index_params = {
        page: null,
        per_page: null, //TODO: this is to counter this.$d.request's default_index_params
        with_properties: [],
      };

      temp_index_params = Object.assign(temp_index_params, index_params);

      this.$d.request(
        'index',
        this.class_name,
        temp_index_params,
        class_data => {
          console.log('class_data', class_data);

          class_data.forEach(params => {
            let id = params['id'];
            this.eager_loaded_by_id[id] = _.assign(this.eager_loaded_by_id[id], params);
            let index = _.findIndex(this.pagination.data, { id: id });
            if (index >= 0) {
              this.pagination.data[index] = _.assign(this.pagination.data[index], params);
              this.$set(this.pagination.data, index, this.pagination.data[index]);
            }
          });
          console.log('eager_loaded_by_id', this.eager_loaded_by_id);
        },
        (error_message, is_cancelled) => {
          this.error_message = error_message;

          if (is_cancelled) {
          } else if (!this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          } else {
            console.error(error_message);
          }
        },
        (class_data, error_message, is_cancelled) => {
          if (!is_cancelled) {
            this.$NProgress.done();

            this.loading = false;
          }
          //end
        },
        {},
        this.prev_source_eager_load,
        new_source => {
          this.prev_source_eager_load = new_source;
        }
      );
    },
    get_undebounced(extra_index_params = {}) {
      this.$NProgress.start();

      this.error_message = null;
      this.success_message = null;
      this.manual_get_needed = false;

      this.loading = true;

      let request_datetime = moment();

      var index_params = {
        // index_withs: true,
      };

      if (this.with_childs_mode) {
        index_params['with_childs'] = {
          with_virtual_properties: true,
          index_withs: true,
        };
      }

      index_params = Object.assign(index_params, this.combined_index_params);
      index_params = Object.assign(index_params, extra_index_params);

      this.$d.request(
        'index',
        this.class_name,
        index_params,
        (class_data, pagination) => {
          // console.log('class_data', class_data);
          // console.log('pagination', pagination);

          pagination.data = this.mergeWithEagerLoaded(pagination.data);
          console.log('mergeWithEagerLoaded', _.cloneDeep(pagination.data));

          this.pagination = _.omit(this.pagination, ['total']); //ensures total is not cached
          this.pagination = _.assign(this.pagination, pagination); //must be assign, otherwise will have remnants

          // this.$set(this, 'pagination', pagination) // this.pagination = pagination; //cannot use this method, don't know why causes pagination button unresponsive/unreactive
          this.pagination['page'] = pagination['current_page']; //set actual page from server

          /* let per_page = this.pagination['per_page'];
            if (!this.per_page_options.includes(per_page)) {
              this.extra_per_page_options = [per_page];
            } */
          /* let max_per_page = this.pagination['total'];
            if (!this.per_page_options.includes(max_per_page)) {
              this.extra_per_page_options = [max_per_page];
            } */

          if (this.cache_key) {
            console.log('save pagination', this.cache_key, this.pagination);

            this.$d.setCache(this.cache_key, this.pagination);
          }

          this.$forceUpdate();

          this.loaded_from_server = true;

          this.response_datetime = moment();
        },
        (error_message, is_cancelled) => {
          this.error_message = error_message;

          if (is_cancelled) {
          } else if (!this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          } else {
            console.error(error_message);
          }
        },
        (class_data, error_message, is_cancelled) => {
          if (!is_cancelled) {
            this.$NProgress.done();

            this.loading = false;

            if (this.response_datetime && request_datetime) {
              this.timetaken = this.response_datetime.diff(request_datetime, 'ms');
            }
          }
          //end
        },
        {},
        this.prev_source,
        new_source => {
          this.prev_source = new_source;
        }
      );
      this.ideal_params['class_name'] = this.class_name;
      this.$d.request(
          'latest',
          'DMaterialize',
          // 'index',
          // this.class_name,
          this.ideal_params, //parameter
          class_data => {
            console.log('latest DMaterialize', class_data);
            this.latest_d_materialize = class_data['d_materialize_datetime'];
            // this.loading = false;
            // resolve(class_data);
          },
          error_message => {
            console.log(error_message);
            // this.loading = false;
            // reject(error_message);
          }
      );
    },
    downloadExcel() {
      var index_params = this.combined_index_params;

      this.downloading = true;
      index_params['export_csv'] = true;
      this.$d.downloadRequest(
        'index',
        this.class_name,
        index_params,
        () => {
          this.downloading = false;
        },
        () => {
          this.downloading = false;
        }
      );
    },
    getStartCase(name) {
      return _.startCase(name);
    },
    setupHasRelationshipWithClasses() {
      this.has_relationship_with_classes = [];

      if (this.introspect.relationships) {
        // Search with relation item Id
        this.introspect.relationships.forEach(relationship => {
          if (_.get(relationship, ['frontend', 'hidden'])) {
            return; //skip
          }
          if (_.get(relationship, ['frontend', 'hidden_in_filter'])) {
            return; //skip
          }
          if (relationship['from']['class_name'] === this.class_name) {
            this.has_relationship_with_classes.push({
              filter_key: this.$d.getRelationshipFilterKey(relationship, 'to'),
              dropdown_label: _.startCase(relationship['relationship_name']),
              to_class_name: relationship['to']['class_name'],
              from_class_name: relationship['from']['class_name'],
              target_class_name: relationship['to']['class_name'],
              relationship: relationship,
            });
          }
        });
      }

      if (!_.isNil(this.has_relationship_with_classes)) {
        this.has_relationship_with_classes.forEach(item => {
          this.$set(this.simple_relationships_filter, item.filter_key, null);
        });
      }
    },
    setupHaveClassesRelationshipWith() {
      this.have_classes_relationship_with = [];

      if (this.introspect.relationships) {
        // Search with relation item Id
        this.introspect.relationships.forEach(relationship => {
          if (_.get(relationship, ['frontend', 'hidden'])) {
            return; //skip
          }
          if (_.get(relationship, ['frontend', 'hidden_in_filter'])) {
            return; //skip
          }
          if (relationship['to']['class_name'] === this.class_name) {
            this.have_classes_relationship_with.push({
              filter_key: this.$d.getRelationshipFilterKey(relationship, 'from'),
              dropdown_label: _.startCase(relationship['from']['class_name']) + ' (' + _.startCase(relationship['relationship_name']) + ')',
              to_class_name: relationship['to']['class_name'],
              relationship: relationship,
              from_class_name: relationship['from']['class_name'],
              target_class_name: relationship['from']['class_name'],
            });
          }
        });
      }

      if (!_.isNil(this.have_classes_relationship_with)) {
        this.have_classes_relationship_with.forEach(item => {
          this.$set(this.simple_relationships_filter, item.filter_key, null);
        });
      }
    },
    lastUpdatedDatetimeForMysqlViewMaterialized(last_updated_date_time_for_mysql_view_materialized) {
      console.log('last_updated_date_time_for_mysql_view_materialized', last_updated_date_time_for_mysql_view_materialized);
      return this.lastUpdatedDatetimeForMysqlViewMaterialized;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/previewImage';

.search  {
  .table.table-hover {
    cursor: default; //not clickable by default, unless specified
  }

  .table-sm .table_actions {
    .btn-sm,
    .btn-group-sm > .btn {
      padding: 0.1rem 0.5rem;
    }
  }

  .index_table {
    tbody > tr > td {
      white-space: pre-wrap;
      @media (min-width: 576px) {
        white-space: pre;
      }
    }

    &.clickable {
      tr,
      td {
        cursor: pointer;
      }
    }
  }

  .index_table  tbody > tr > td.no_break {
    white-space: nowrap !important;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .table, td.no_horizontal_padding {
    padding-left: 0;
    padding-right: 0;
  }

  .table, td.no_padding {
    padding: 0;
  }

  .toggles  .form-group {
    margin-bottom: 0;
  }

  .hidden_copyable_text {
    //ref: https://stackoverflow.com/a/37927056/3553367
    color: rgba(0, 0, 0, 0); //transparent text
    font-size: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}
</style>
