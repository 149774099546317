<template>
  <DescriptorCard
    class_name="CallScheduled"
    title="CallScheduled"
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    :_search_component="_search_component"
  ></DescriptorCard>
</template>

<script>
import CallScheduledSearch from './CallScheduledSearch';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    _search_component() {
      return CallScheduledSearch;
    },
  },
  created() {},
  methods: {},
};
</script>
