<template>
  <b-row>
    <b-col v-for="(property, index) in filterable_properties" :key="index" md="6" class="my-1">
      <b-form-group :label="property['property_name']" :label-cols="12" :label-cols-sm="6" :label-size="small ? 'sm' : null" class="mb-0">
        <div slot="label" v-b-tooltip.hover.topleft :title="property['description'] ? property['description'] : null">{{ property['property_name'] }}</div>
        <div v-if="property['type'] == 'date' || property['type'] == 'datetime'">
          <!-- {{ params_[property['property_key']] }} -->
          <VueCtkDateTimePicker
            v-model="params_[property['property_key']]"
            :range="true"
            :format="displayFormatFromPropertyType(property['type'])"
            :output-format="outputFormatFromPropertyType(property['type'])"
            :onlyDate="property['type'] == 'date' ? true : false"
            :onlyTime="property['type'] == 'time' ? true : false"
            :no-value-to-custom-elem="true"
            :no-shortcuts="true"
          >
            <!-- :autoClose="property['type'] == 'datetime' ? false : true" -->
            <div>
              <b-input-group>
                <b-form-input
                  type="text"
                  :size="small ? 'sm' : null"
                  :autocomplete="$d.randStr()"
                  :placeholder="'Start'"
                  :value="
                    params_[property['property_key']]['start']
                      ? moment(params_[property['property_key']]['start'], outputFormatFromPropertyType(property['type'])).format(
                          displayFormatFromPropertyType2(property['type'])
                        )
                      : null
                  "
                  @input="
                    val => {
                      cleanDatetimeDebounced('start', 'end', property, val);
                    }
                  "
                ></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-form-input
                  type="text"
                  :size="small ? 'sm' : null"
                  :autocomplete="$d.randStr()"
                  :placeholder="'End'"
                  :value="
                    params_[property['property_key']]['end']
                      ? moment(params_[property['property_key']]['end'], outputFormatFromPropertyType(property['type'])).format(
                          displayFormatFromPropertyType2(property['type'])
                        )
                      : null
                  "
                  @input="
                    val => {
                      cleanDatetimeDebounced('end', 'start', property, val);
                    }
                  "
                ></b-form-input>
              </b-input-group>
            </div>
          </VueCtkDateTimePicker>
        </div>
        <div v-else-if="property['type'] == 'number'">
          <FormInput
            :propertyType="property['type']"
            :number_config="{
              scale: _.get(property, ['rules', 'default', 'scale'], 1),
              min: _.get(property, ['rules', 'default', 'min']),
              max: _.get(property, ['rules', 'default', 'max']),
            }"
            :extParam.sync="params_[property['property_key']]['min']"
            :prefix="property['prefix']"
            :suffix="property['suffix']"
            :size="small ? 'sm' : null"
            placeholder="Min"
          ></FormInput>
          <FormInput
            :propertyType="property['type']"
            :number_config="{
              scale: _.get(property, ['rules', 'default', 'scale'], 1),
              min: _.get(property, ['rules', 'default', 'min']),
              max: _.get(property, ['rules', 'default', 'max']),
            }"
            :extParam.sync="params_[property['property_key']]['max']"
            :prefix="property['prefix']"
            :suffix="property['suffix']"
            :size="small ? 'sm' : null"
            placeholder="Max"
          ></FormInput>
        </div>
        <div v-else-if="property['type'] == 'id'">
          <FormInput
            propertyType="string"
            :semicolon_as_array="true"
            :number_config="{
              scale: _.get(property, ['rules', 'default', 'scale'], 1),
              min: _.get(property, ['rules', 'default', 'min']),
              max: _.get(property, ['rules', 'default', 'max']),
            }"
            :extParam.sync="params_[property['property_key']]"
            :prefix="property['prefix']"
            :suffix="property['suffix']"
            :size="small ? 'sm' : null"
          ></FormInput>
        </div>
        <div v-else>
          <FormInput
            :propertyType="property['type']"
            :number_config="{
              scale: _.get(property, ['rules', 'default', 'scale'], 1),
              min: _.get(property, ['rules', 'default', 'min']),
              max: _.get(property, ['rules', 'default', 'max']),
            }"
            :extParam.sync="params_[property['property_key']]"
            :prefix="property['prefix']"
            :suffix="property['suffix']"
            :size="small ? 'sm' : null"
          ></FormInput>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';

import FormInput from './FormInput';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  components: {
    FormInput,
    VueCtkDateTimePicker,
  },
  props: {
    properties: {
      type: Array,
    },
    params: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      params_: {},
    };
  },
  computed: {
    filterable_properties() {
      let filterable_properties = this.properties;

      filterable_properties = filterable_properties.filter(property => {
        return !property['hidden_in_filter'];
      });

      filterable_properties = filterable_properties.filter(property => {
        return !property['virtual'] && property['type'] !== 'point' && property['type'] !== 'polygon' && property['type'] !== 'base64';
      });

      filterable_properties = filterable_properties.map(property => {
        /* if(property['type'] == 'id'){
          property['type'] = 'string'; //TODO: temporarily convert as string to allow comma based search
        } */
        return property;
      });
      // return this.properties;
      return filterable_properties;
    },
    picker_datetime_format() {
      // return this.$d.datetime_format
      return this.$d.date_format + ' ' + this.$d.time_format;
      //So that it only shows pure date and pure time in the picker
    },
  },
  watch: {
    params_: {
      deep: true,
      handler: function(new_val, old_val) {
        console.log('changed', new_val);
        this.$emit('update:params', new_val);
      },
    },
    filterable_properties(to, from) {
      console.log('filterable_properties changed');
      this.initVariables();
    },
  },
  created() {
    this._ = _;
    this.moment = moment;
    this.initVariables(); //need to be done before render
    this.cleanDatetimeDebounced = _.debounce(this.cleanDatetime, 1500);
  },
  mounted() {},
  methods: {
    displayFormatFromPropertyType2(property_type) {
      return property_type == 'datetime'
        ? this.$d.datetime_format
        : property_type == 'date'
        ? this.$d.date_format
        : property_type == 'time'
        ? this.$d.time_format
        : null;
    },
    displayFormatFromPropertyType(property_type) {
      return property_type == 'datetime'
        ? this.picker_datetime_format
        : property_type == 'date'
        ? this.$d.date_format
        : property_type == 'time'
        ? this.$d.time_format
        : null;
    },
    outputFormatFromPropertyType(property_type) {
      return property_type == 'datetime' ? 'YYYY-MM-DD HH:mm:ss' : property_type == 'date' ? 'YYYY-MM-DD' : property_type == 'time' ? 'HH:mm:ss' : null;
    },
    cleanDatetime(key_val, key_copy, property, val) {
      val.trim();
      if (val == '') {
        val = null;
      } else {
        val = moment(val, this.displayFormatFromPropertyType2(property['type'])).format(this.outputFormatFromPropertyType(property['type']));
      }
      var obj = {};
      obj[key_val] = val;
      obj[key_copy] = this.params_[property['property_key']][key_copy];

      this.params_[property['property_key']] = obj; //hack for reactivity
    },
    initVariables() {
      this.params_ = {}; //clean

      this.filterable_properties.forEach(property => {
        if (property['type'] == 'date' || property['type'] == 'datetime') {
          this.$set(this.params_, property['property_key'], {
            start: null,
            end: null,
          });
        } else if (property['type'] == 'number') {
          this.$set(this.params_, property['property_key'], {
            min: null,
            max: null,
          });
        } else {
          this.$set(this.params_, property['property_key'], null);
        }
      });
    },
  },
};
</script>
