<template>
  <b-form-group :label-cols="12" :label-cols-sm="2" label="Search" class="mb-0" :label-size="size">
    <b-input-group>
      <b-form-input
        :disabled="disabled"
        @input="value => $emit('update:search', value)"
        :value="search"
        :placeholder="getPlaceholder"
        :size="size"
        :autocomplete="$d.randStr()"
      />
      <b-input-group-append>
        <b-btn :disabled="!search" @click="$emit('update:search', '')" :size="size">Clear</b-btn>
      </b-input-group-append>
      <b-button-group v-if="!is_mysql_view_materialized" class="pull-right" style="margin-left: 10px">
        <b-button @click.stop="$emit('refresh')" variant="outline-dark" v-b-tooltip.hover title="Reload" :size="size">
          <i class="fa fa-sync-alt"></i> <!-- v-if="!is_mysql_view_materialized" -->
        </b-button>
      </b-button-group>
      <b-button-group v-if="is_mysql_view_materialized" class="pull-right" style="margin-left: 10px">
        <b-button @click.stop="reloadMysqlViewMaterialized()" variant="outline-dark" v-b-tooltip.hover title="Rematerialize Table" :size="size">
           
           <span v-show="!loading">
             <i class="fa fa-cogs"></i>
           </span>
           <span v-show="loading">  
             <q-spinner color="primary" size="17px" :thickness="2" />
           </span>
        </b-button>
      </b-button-group>
    </b-input-group>
  </b-form-group>
</template>
<script>
import { QSpinner } from 'quasar';

export default {
  components: {
    QSpinner,
  },
  props: {
    search: {},
    size: {
      // type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    with_properties: {},
    is_mysql_view_materialized: {
      type: Boolean,
      default: null,
    },
    class_name: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      ideal_params: {
        class_name: null,
      },
      loading: false,
    };
  },
  mounted() {},
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    getPlaceholder() {
      var placeholder = '';
      if (this.with_properties) {
        this.with_properties.forEach(property => {
          if (property['searchable']) {
            placeholder += property['property_name'] + '    ';
            // console.log('searchable: ', property['property_name']);
          }
        });
      }
      return placeholder != '' ? placeholder : null;
    },
  },
  watch: {},
  methods: {
    reloadMysqlViewMaterialized() {
      let self = this;

      self.callMysqlViewMaterializedApi().then(function(resultA) {
        console.log(resultA);
        return self.reloadTable(resultA);
      }).then(function(resultB) {
          console.log(resultB);
      });
    },
    callMysqlViewMaterializedApi() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.ideal_params['class_name'] = this.class_name;
        this.$d.request(
          'mysql_view_materialized',
          'DMaterialize',
          // 'index',
          // this.class_name,
          this.ideal_params, //parameter
          class_data => {
            console.log('class_data', class_data);
            this.loading = false;
            resolve(class_data);
          },
          error_message => {
            console.log(error_message);
            this.loading = false;
            reject(error_message);
          }
        );     
      });
      
    },
    reloadTable(last_updated_date_time) {
      return new Promise((resolve, reject) => {
        this.$emit('refresh');
        this.$emit('send_last_updated_date_time', last_updated_date_time);
        this.ideal_params['d_materialize_datetime'] = last_updated_date_time;
        resolve('Reloading table after calling MysqlViewMaterialized API');
      });    
    },
  },
  created() {},
};
</script>
