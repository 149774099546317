<template>
  <div class="search">
    <span v-if="from && to">
      <span class="float-left" v-if="total">{{ from }} - {{ to }} / {{ total }}</span>
      <span class="float-left" v-else>{{ from }} - {{ to }}</span>
    </span>
    <span v-if="timetaken" class="float-left">&nbsp; ({{ timetaken / 1000 }}s)</span>
    <span v-if="!is_mysql_view_materialized && response_age" class="float-left" :style="{ color: response_color }">&nbsp; {{ response_age }}</span>
    <span v-if="is_mysql_view_materialized">
      <span v-if="latest_d_materialize" class="float-left">
        &nbsp;- <strong>Last materialized 
                <span v-if="materialized_age" :style="{ color: materialized_color }">{{ materialized_age }}</span>
                ({{ latest_d_materialize }})</strong>
        <!-- <span v-if="materialized_age" class="float-right" :style="{ color: materialized_color }">&nbsp;<strong>({{ materialized_age }})</strong></span> -->
      </span>
    </span>
    
    <span v-if="per_page !== 'all'" class="float-right">
      <b-pagination
        align="right"
        :size="small ? 'sm' : ''"
        :limit="limit_"
        hide-ellipsis
        :total-rows="total_"
        :per-page="per_page"
        v-model="page_"
        prev-text="Prev"
        next-text="Next"
        first-text="First"
        last-text="Last"
        :disabled="disabled"
      />
      <!-- @input="get()" -->
      <!-- v-if="total" -->
      <!-- <b-button-group v-else>
        <b-button>Button 1</b-button>
        <b-button>Button 2</b-button>
      </b-button-group> -->
    </span>
    <span class="float-right"> </span>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {},
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    from: {
      type: Number,
    },
    to: {
      type: Number,
    },
    total: {
      type: Number,
    },
    page: {
      type: Number,
    },
    per_page: {
      // type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    timetaken: {
      type: Number,
    },
    latest_d_materialize: {
      type: String,
      default: null,
    },
    is_mysql_view_materialized: {
      type: Boolean,
      default: null,
    },
    response_datetime: {},
    update_interval: {
      type: Number,
      default: 1000,
    },
  },
  data: () => {
    return {
      datetime_now: moment(),
      interval_obj: null,
    };
  },
  computed: {
    page_: {
      // getter
      get: function() {
        return this.page;
      },
      // setter
      set: function(newValue) {
        this.$emit('update:page', newValue);
      },
    },
    total_() {
      if (this.total) {
        return this.total;
      }

      return 1000;
    },
    limit_() {
      if (this.total) {
        //if pagination gave the total numeber of rows
        return 9;
      }
      // return 5;
      return 1;
    },
    response_age() {
      if (this.response_datetime) {
        //ref: https://stackoverflow.com/a/55916701/3553367
        return this.response_datetime.from(this.datetime_now);
      }
      return null;
    },
    response_color() {
      if (this.response_datetime) {
        //ref: https://stackoverflow.com/a/55916701/3553367
        let seconds_ago = this.datetime_now.diff(this.response_datetime, 'seconds');
        // console.log(seconds_ago);
        if (seconds_ago >= 4.5 * 60) {
          return '#ff0000';
        }
      }
      return null;
    },
    materialized_age() {
      if (this.latest_d_materialize) {
        return moment(this.latest_d_materialize).from(this.datetime_now);
      }
      return null;
    },
    materialized_color() {
      if (this.latest_d_materialize) {
        let seconds_ago = this.datetime_now.diff(moment(this.latest_d_materialize), 'seconds');
        if (seconds_ago >= 4.5 * 60) {
          return '#ff0000';
        }
      }
      return null;
    },
  },
  methods: {
    init() {},
  },
  created() {
    this._ = _;
    
    this.interval_obj = setInterval(() => {
      this.datetime_now = moment();
    }, this.update_interval);

    this.init();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.interval_obj);
    this.interval_obj = null;
  },
};
</script>

<style lang="scss" scoped></style>
