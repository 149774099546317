<template>
  <div style="height: 100%">
    <GmapMap :center="map.center" :zoom="12" map-type-id="roadmap" style="width: auto; min-height: 400px;height: 100%" ref="map">
      <GmapMarker v-for="(marker, ind) in map.points" :key="'marker_' + ind" :clickable="true" :position="marker.position" :draggable="false" />

      <gmap-info-window v-for="(info, i) in map.points" :key="'info_window_' + i" :options="infoOptions" :position="info.position" :opened="info.isInfoOpen">{{
        info.name
      }}</gmap-info-window>

      <gmap-polygon
        v-for="(poly, index) in map.polygon"
        :key="'polygon_' + index"
        :paths="poly.polygon"
        :editable="false"
        @mouseover="onMouseOver($event, poly)"
        @mouseout="onMouseOut($event, poly)"
        ref="polygon"
      ></gmap-polygon>
    </GmapMap>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    extParam: {
      required: true,
    },
    class_name: {
      type: String,
      required: true,
    },
    show_ids: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      param: null,
      propertyType: null,
      map: {
        center: { lat: 3.0467364, lng: 101.6347433 },
        points: [],
        polygon: [],
      },
      infoContent: '',
      infoWindowPos: null,
      currentMidx: null,
      infoWinOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  watch: {
    extParam: {
      handler: function(extParam) {
        this.param = JSON.parse(JSON.stringify(extParam));
        if (this.param) {
          this.updatePolygonAndMarker();
        }
      },
      deep: true,
    },
    show_ids: {
      handler: function(extParam) {
        this.updatePolygonAndMarker();
      },
      deep: true,
    },
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    class_() {
      return this.$d.getClass(this.class_name);
    },
  },
  mounted() {
    this.param = JSON.parse(JSON.stringify(this.extParam));
    if (this.param) {
      this.updatePolygonAndMarker();
    }
  },
  methods: {
    getPolygonCenterPointTitle(index) {
      var class_ = this.class_;
      if (class_ && class_['frontend'] && class_['frontend']['index'] && class_['frontend']['index']['map'] && class_['frontend']['index']['map']['title']) {
        return class_['frontend']['index']['map']['title'][index];
      }
      return '';
    },
    updatePolygonAndMarker: function() {
      this.map = {
        center: { lat: 3.0467364, lng: 101.6347433 },
        points: [],
        polygon: [],
      };

      this.param = _.map(this.param, function(element) {
        return _.extend({}, element, { polygonName: '' });
      });

      this.findDeeperPolygon(JSON.parse(JSON.stringify(this.param)), null);
      this.findDeeperPoint(JSON.parse(JSON.stringify(this.param)), null);

      if (this.map.polygon.length > 0) {
        this.$refs.map.$mapPromise.then(map => {
          const bounds = new google.maps.LatLngBounds();
          this.map.polygon.forEach(area => {
            area.polygon.forEach(points => {
              bounds.extend(points);
            });
          });
          map.fitBounds(bounds);
        });
      } else {
        this.$refs.map.$mapPromise.then(map => {
          const bounds = new google.maps.LatLngBounds();
          this.map.points.forEach(point => {
            bounds.extend(point.position);
          });
          map.fitBounds(bounds);
        });
        // this.map.center = Object.assign({}, this.map.points[0].position);
      }
      this.$forceUpdate();
    },
    getTitle(array, path) {
      if (Array.isArray(path)) {
        //multiple paths per transversal
        let combined_title = '';
        path.forEach((single_path, index) => {
          combined_title += _.get(array, single_path);
          if (index + 1 < path.length) {
            combined_title += ' - ';
          }
        });
        return combined_title;
      } else {
        return _.get(array, path);
      }
    },
    findDeeperPolygon(param, levelTitle) {
      var class_ = this.class_;
      param.forEach(area => {
        if (levelTitle && levelTitle != '' && levelTitle != 'null') {
          if (area.polygonName == '') {
            area.polygonName = levelTitle;
          } else {
            area.polygonName = area.polygonName + ' - ' + levelTitle;
          }
        }
        if (this.show_ids.includes(area['id']) || area.is_shown_on_map) {
          if (class_['frontend']['index']['map']['polygon']) {
            class_['frontend']['index']['map']['polygon'].forEach((option, index) => {
              var title = this.getPolygonCenterPointTitle(index);
              if (option && Array.isArray(area[option])) {
                area[option] = _.map(area[option], element => {
                  return _.extend({}, element, { is_shown_on_map: this.show_ids.includes(area['id']) || area.is_shown_on_map, polygonName: '' });
                });
                if (title && title != '' && title != 'null') {
                  if (area.polygonName == '') {
                    area.polygonName = this.getTitle(area, title);
                  } else {
                    area.polygonName = levelTitle + ' - ' + this.getTitle(area, title);
                  }
                }
                this.findDeeperPolygon(area[option], area.polygonName);
              } else {
                if (area[option]) {
                  if (title && title != '' && title != 'null') {
                    if (area.polygonName == '') {
                      area.polygonName = this.getTitle(area, title);
                    } else {
                      area.polygonName = levelTitle + ' - ' + this.getTitle(area, title);
                    }
                  }
                  this.prepareDataForPolygon(area, option, area.polygonName);
                }
              }
            });
          }
        }
      });
    },

    //TODO: this code does not handle multiple points properly
    findDeeperPoint(param, levelTitle) {
      var class_ = this.class_;
      console.log('levelTitle ====> ' + levelTitle);
      param.forEach(area => {
        if (levelTitle && levelTitle != '' && levelTitle != 'null') {
          area.polygonName = levelTitle;
        }
        if (this.show_ids.includes(area['id']) || area.is_shown_on_map) {
          if (class_['frontend']['index']['map']['center_point']) {
            class_['frontend']['index']['map']['center_point'].forEach((option, index) => {
              var title = this.getPolygonCenterPointTitle(index);
              console.log('title location: ', title);
              if (option && Array.isArray(area[option])) {
                area[option] = _.map(area[option], element => {
                  return _.extend({}, element, { is_shown_on_map: this.show_ids.includes(area['id']) || area.is_shown_on_map, polygonName: '' });
                });
                if (title && title != '' && title != 'null') {
                  if (area.polygonName == '') {
                    area.polygonName = this.getTitle(area, title);
                  } else {
                    area.polygonName = levelTitle + ' - ' + this.getTitle(area, title);
                  }
                }
                this.findDeeperPoint(area[option], area.polygonName);
              } else {
                if (area[option]) {
                  if (title && title != '' && title != 'null') {
                    if (area.polygonName == '') {
                      area.polygonName = this.getTitle(area, title);
                    } else {
                      area.polygonName = levelTitle + ' - ' + this.getTitle(area, title);
                    }
                  }
                  this.prepareDataForPoint(area[option], area.polygonName, area.id);
                }
              }
            });
          }
        }
      });
    },

    prepareDataForPolygon: function(area, key, name) {
      var data = area[key].coordinates.map(linearRing => linearRing.slice(0, linearRing.length - 1).map(([lng, lat]) => ({ lat, lng })));

      var polygonObj = {
        id: area.id,
        name: name,
        polygon: data[0],
        center: null,
      };
      polygonObj.center = polygonObj.polygon[0];
      this.map.polygon.push(polygonObj);
    },
    prepareDataForPoint: function(point, name, id) {
      this.map.points.push({
        id: id,
        position: {
          lng: point.coordinates[0],
          lat: point.coordinates[1],
        },
        name: name,
        isInfoOpen: true,
      });
    },
    onMouseOver(event, polygon) {
      // this.map.center = Object.assign({}, polygon.center);
    },
    onMouseOut(event, poygon) {
      // this.map.center = Object.assign({}, this.map.points[0]);
    },
  },
};
</script>

<style lang="scss">
.gm-style-iw {
  font-weight: 500 !important;
}

.gm-style-iw ~ button.gm-ui-hover-effect {
  display: none !important;
}
</style>
