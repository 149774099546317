<template>
  <Search
    v-bind="Object.assign({}, $attrs)"
    v-on="$listeners"
    ref="childRef"
    :_predefined_filters="predefined_filters"
    :_property_cell_variants="property_cell_variants"
  >
    <!-- ref: https://stackoverflow.com/a/50892881/3553367 -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Search>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import Search from '@/descriptor/coreui/Search'; //cannot use dynamic import or else this.$refs will be undefined

export default {
  components: {
    Search,
  },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    property_cell_variants() {
      return [
        {
          property_key: 'scheduled_datetime',
          cellVariantFunc: params => {
            if (params['is_call_done'] >= 1) {
              return 'success';
            }
            let diff_days = this.$d
              .momentSynced()
              .startOf('day')
              .diff(params['scheduled_datetime'], 'days');
            // console.log(diff_days);
            if (diff_days > 0) {
              //BEFORE TODAY
              return 'danger';
            } else if (diff_days == 0) {
              //TODAY
              return 'warning';
            }
            return null;
          },
        },
        {
          property_key: 'is_call_done',
          cellVariantFunc: params => {
            if (params['is_call_done'] >= 1) {
              return 'success';
            }
            return 'danger';
          },
        },
      ];
    },
    predefined_filters() {
      return [
        {
          filter_name: 'Is Overdue',
          filter_description: 'Calls later than 1 day',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'scheduled_datetime',
                max: this.$d.momentFormatDate(this.$d.momentSynced().subtract(1, 'week')),
              },
              {
                filter_type: 'property',
                existence: 'absent',
                property_key: 'is_call_done',
                equal: true,
              },
            ],
          },
          frontend: {
            // variant: 'success',
            variant: 'danger',
          },
        },
        {
          filter_name: 'Is Done',
          filter_description: 'Call was made',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'is_call_done',
                equal: true,
              },
            ],
          },
          frontend: {
            variant: 'success',
            // variant: 'warning',
            // variant: 'danger',
          },
        },
        {
          filter_name: 'Is Not Done',
          filter_description: 'Call was not made',
          filter_params: {
            filters: [
              {
                filter_type: 'property',
                existence: 'absent',
                property_key: 'is_call_done',
                equal: true,
              },
            ],
          },
          frontend: {
            // variant: 'success',
            // variant: 'warning',
            variant: 'danger',
          },
        },
      ];
    },
  },
  created() {},
  mounted() {
    Object.keys(this.$refs['childRef'].$options.methods).forEach(method_name => {
      //IMPORTANT NOTE: the vue ref may be undefined initially, make sure this wrapper component is not imported dynamically, no v-if https://stackoverflow.com/a/63233540/3553367
      //TODO: use mixin?
      if (!this.$options.methods[method_name] && !this[method_name]) {
        console.warn('automatically inherit child component method:', method_name);
        this[method_name] = (...args) => {
          console.warn('automatically called child component method:', method_name);
          if (args && args.length) {
            console.warn('arguments:', args);
          }
          this.$refs['childRef'][method_name](...args);
        };
      }
    });
    console.log(this.$options.methods);
  },
  methods: {},
};
</script>
