<template>
  <div v-if="introspect.relationships && relationship">
    <b-form-select v-if="relationship_options" :options="relationship_options" :value="selected_value" @change="onSelected($event, relationship)" :size="size">
      <!--<option slot="first" :value="null">-</option>-->
      <!--<option slot="first" :value="true">True</option>-->
      <!--<option slot="first" :value="false">False</option>-->
    </b-form-select>

    <b-form-select v-else disabled :value="null">
      <option :value="null">Loading...</option>
    </b-form-select>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FilterDropdown',
  components: {},
  props: {
    size: {
      default: null,
    },
    relationship_type: {
      type: String,
    },
    target_class_name: {
      type: String,
    },
    relationship: {
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
      // required: true
    },
    allow_predefined_filter: {
      type: Boolean,
      default: true,
    },
    selected_option: {},
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      relationship_options: null,
      //   relationship: null,
      filter_param_name: null,
      common_relationship_options: [{ value: null, text: '-' }, { value: true, text: 'True' }, { value: false, text: 'False' }],
      is_options_loaded: false,
    };
  },
  mounted() {},
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    selected_value() {
      if (Array.isArray(this.selected_option)) {
        return _.get(this.selected_option, [0, 'id'], null);
      }
      return this.selected_option;
    },
    class_() {
      return this.$d.getClass(this.target_class_name);
    },
    predefined_filters() {
      return this.class_['predefined_filters'];
    },
    enabled_predefined_filters() {
      return _.get(this.class_, ['default_filters'], []);
    },
    predefined_filters_index_params() {
      let index_params = {};
      this.enabled_predefined_filters.forEach(enabled_predefined_filter => {
        let predefined_filter = _.find(this.predefined_filters, function(o) {
          return o['filter_name'] == enabled_predefined_filter;
        });

        if (predefined_filter) {
          this.$d.mergeFilterParams(index_params, predefined_filter['filter_params']);
        } else {
          console.error('The enabled_predefined_filter: ' + enabled_predefined_filter + ' is not found');
        }
      });
      return index_params;
    },
    combined_index_params() {
      let index_params = {};

      if (this.allow_predefined_filter) {
        index_params = _.merge(index_params, this.predefined_filters_index_params);
      }

      return index_params;
    },
    title_property_keys() {
      return this.$d.getTitlePropertyKeys(this.class_)
    },
  },
  watch: {
    enabled(to, from) {
      if (!this.is_options_loaded && this.enabled == true) {
        this.initOptions();
      }
    },
  },
  created() {
    this.initOptions();
  },
  methods: {
    initOptions() {
      if (this.class_['frontend'] && this.class_['frontend']['dropdown'] && this.class_['frontend']['dropdown']['index'] === true) { //IMPORTANT TODO: should be default to index only if 'true'
        if (this.enabled) {
          // console.log('get index relationship target class', this.relationship['from']['class_name'], this.relationship['relationship_name'], this.relationship['to']['class_name']);
          this.get(this.relationship);
        }
      } else {
        // console.log('dont index relationship target class', this.relationship['from']['class_name'], this.relationship['relationship_name'], this.relationship['to']['class_name']);
        this.relationship_options = this.common_relationship_options;
      }
    },
    onSelected(event, relationship) {
      var _that = this;

      var selected_value = event;
      var option = this.relationship_options.find(option => {
        return option.value === event;
      });

      if (option && (selected_value != true && selected_value != false && selected_value != null)) {
        //dropdown is always assuming only 1 item
        this.$emit('update:selected_option', [option.real_data]);
      } else {
        this.$emit('update:selected_option', selected_value);
      }
    },
    getRelationAliasFromRule(rule, relationship) {
      return _.snakeCase(rule[relationship]['from_class_name'] + rule[relationship]['relationship_name']);
    },
    getStartCase(text) {
      return _.startCase(text);
    },
    get(relationship) {
      this.error_message = null;
      this.success_message = null;

      let index_params = {};

      index_params['with_virtual_properties'] = [];
      index_params['properties_sort'] = [];

      this.title_property_keys.forEach((title_property_key, index,) => {
        let title_property =  this.$d.getProperty(this.class_['properties'], title_property_key);
        let is_virtual = false;
        if(title_property){
          is_virtual = _.get(title_property, ['virtual_property'], false) == true
        }
        if(is_virtual){
          index_params['with_virtual_properties'].push(title_property_key)
        }
        index_params['properties_sort'].push(
          {
            property_key: title_property_key,
            direction: "asc"
          })
      })

      index_params = _.merge(index_params, this.combined_index_params);

      var config = {
        method: 'post',
        url: 'descriptor/' + this.class_['class_key'] + '/index',
        data: index_params,
      };

      this.$api(config)
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            this.relationship_options = [];

            var real_data = data[this.class_['class_key']];

            this.relationship_options = this.common_relationship_options;

            real_data.forEach(item => {
              var class_ = this.$d.getClass(relationship['to']['class_name']);
              this.relationship_options.push(this.$d.convertToDropdownOption(class_, item));
            });

            this.is_options_loaded = true;
            this.$forceUpdate();
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (error) {
            this.error_message = this.$api.getValidation(error);
            if (!this.error_message) {
              this.error_message = this.$api.defaultErrorMessage;
            }
          } else {
            console.error(axios_error);
          }
        })
        .then(() => {
          // this.$NProgress.done();
        });
    },
  },
};
</script>
